import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../Checkout/Checkout.scss";
import productimg from "../../assets/img/pro_img.png";
import {
  PayPalButtons,
  PayPalButtons as PayPalSubscriptionButtons,
} from "@paypal/react-paypal-js";
import { Country, State, City } from "country-state-city";
import { addToCart } from "../../redux/slices/cart.scices";
import { useSelector, useDispatch } from "react-redux";

import { useCookies } from "react-cookie";
import { SubmitData } from "../../services/apiService";

const Checkout = () => {
  const [stateList, setStateList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [cookies, setCookie] = useCookies(["sameSite"]);
  const cartProduct = useSelector((state) => state.cart);
  const type = useSelector((state) => state.cart);
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const [productImage, setProductImage] = useState(null);

  const validationSchema = Yup?.object().shape({
    countryCode: Yup?.string().required("Country/Region is required"),
    firstName: Yup?.string().required("First Name is required"),
    lastName: Yup?.string().required("Last Name is required"),
    address1: Yup?.string().required("Address 1 is required"),
    city: Yup?.string().required("City is required"),
    stateCode: Yup?.string().required("State is required"),
    pinCode: Yup?.string()
      .required("Zip Code is required")
      .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code format"),
  });

  const formik = useFormik({
    initialValues: {
      countryCode: "",
      firstName: "",
      lastName: "",
      address1: "",
      city: "",
      stateCode: "",
      pinCode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      setFormSubmitted(true);
      const country = Country?.getCountryByCode(values?.countryCode).name;
      const state = State?.getStateByCodeAndCountry(
        values?.stateCode,
        values?.countryCode
      ).name;
      setFormValues({ ...values, country, state });
    },
  });

  const handleFormEdit = (e) => {
    e.preventDefault();
    setFormSubmitted(false);
  };

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const stateListData = State?.getStatesOfCountry(countryCode);
    setStateList(stateListData);

    formik?.setFieldValue("countryCode", countryCode);
  };

  const handleStates = (event) => {
    const stateCode = event.target.value;
    const cityListData = City.getCitiesOfState(
      formik?.values?.countryCode,
      stateCode
    );

    formik?.setFieldValue("stateCode", stateCode);
  };

  const handleSuccessRedirection = () => {
    localStorage.removeItem("productId");
    localStorage.removeItem("procedure");
    // dispatcher(removeCart({}));
    navigate("/app/confirmation");
  };

  /** FUNCTION USED FOR CREATING CHECKOUT ORDER */
  const createOrder = async () => {
    try {
      const {
        productTitle,
        totalPrice,
        productImageUrl,
        quantity,
        variantData,
      } = cartProduct?.value;
      const payload = {
        totalPrice: totalPrice,
        productTitle: productTitle,
        quantity: quantity,
        sku: variantData?.sku,
        price: variantData?.basePrice,
      };

      setProductImage(productImageUrl);

      const response = await SubmitData("/api/catalog/order/create", payload);
      if (response?.status) {
        return response?.data?.id;
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      console.error(error);
      // alert.success(error.message);
    } finally {
      setPageLoader(false);
    }
  };

  /**FUNCTION USED FOR STORING ORDER AND PAYMENT DETAILS IN DATABASE */
  const onApprove = async (data) => {
    try {
      setPageLoader(true);

      const response = await SubmitData("/api/catalog/order/details/save", {
        orderDetails: data,
        otherDetails: {
          address1: formValues?.address1,
          city: formValues?.city,
          countryCode: formValues?.countryCode,
          firstName: formValues?.firstName,
          lastName: formValues?.lastName,
          pinCode: formValues?.pinCode,
          stateCode: formValues?.stateCode,
          country: formValues?.country,
          state: formValues?.state,
        },
        variantData: cartProduct,
      });
      // const response = await saveOrderDetails(data, formValues, cartProduct);

      // if (response.status) {
      handleSuccessRedirection();
      //   alert.success(response.message);
      // } else {
      //   throw new Error(response.message);
      // }
    } catch (error) {
      // alert.error(error.message);
    } finally {
      setPageLoader(false);
    }
  };

  /** FUNCTION USED FOR STORING ORDER AND PAYMENT DETAILS OF RECURRING ORDERS */
  const onSubscriptionApprove = async (data) => {
    try {
      setPageLoader(true);
      const response = await SubmitData(
        "/api/catalog/order/store-subscription-order",
        {
          orderDetails: data,
          otherDetails: {
            address1: formValues?.address1,
            city: formValues?.city,
            countryCode: formValues?.countryCode,
            firstName: formValues?.firstName,
            lastName: formValues?.lastName,
            pinCode: formValues?.pinCode,
            stateCode: formValues?.stateCode,
            country: formValues?.country,
            state: formValues?.state,
          },
          variantData: cartProduct,
        }
      );

      handleSuccessRedirection();
    } catch (error) {
      // alert.error(error.message);
    } finally {
      setPageLoader(false);
    }
  };

  /** FUNCTION USED FOR CREATING SUBSCRIPTION BASED ORDER */
  const createSubscription = (data, actions) => {
    const data12 = cartProduct?.value;
    // if (!plan) {
    //     console.error("Plan ID is missing or invalid");
    //     return;
    // }

    return actions.subscription.create({
      plan_id: data12.plan.paypalPlanId,
      quantity: data12?.quantity,
      application_context: {
        brand_name: "HRT",
        locale: "en-US",
        shipping_preference: "NO_SHIPPING",
        user_action: "CONTINUE",
        payment_method: {
          payer_selected: "PAYPAL",
          payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
        },
      },
    });
  };

  useEffect(() => {
    const stateListData = State.getStatesOfCountry("US");
    setStateList(stateListData);

    formik?.setFieldValue("countryCode", "US");
    if (cartProduct?.value.productTitle == undefined) {
      // navigate("/app/lab-results");
    }

    setCookie("sameSite", "None");
    setCookie("secure", true);
  }, []);

  return (
    <>
      <Header />
      <div className="main_content payment_page">
        <div className="container">
          <div className="main_block">
            <div className="purchase_detail">
              <div className="row part_wrapper">
                <div className="col-md-6 part left_part">
                  <div className="title_wrapper">
                    <div className="title">Purchase Details</div>
                    <div className="desc">
                      Next Step is fill your purchase details
                    </div>
                  </div>

                  <div className="content_item">
                    <h2 className="content_title">Delivery</h2>
                    <form onSubmit={formik?.handleSubmit}>
                      <div className="form_field_wrapper">
                        <label>
                          Country/Region<span>*</span>
                        </label>
                        <select
                          name="countryCode"
                          id="countryCode"
                          value={formik?.values?.countryCode}
                          onChange={handleCountryChange}
                          onBlur={formik?.handleBlur}
                          disabled={formSubmitted}
                          className={`form-control ${
                            formik?.touched?.countryCode &&
                            formik?.errors?.countryCode
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option key="US" value="US">
                            United States
                          </option>
                        </select>
                        {formik?.touched?.countryCode &&
                        formik?.errors?.countryCode ? (
                          <div className="invalid-feedback">
                            {formik?.errors?.countryCode}
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form_field_wrapper">
                            <label>
                              First Name<span>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="First Name"
                              className={`form-control ${
                                formik?.touched?.firstName &&
                                formik?.errors?.firstName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="firstName"
                              id="firstName"
                              readOnly={formSubmitted}
                              {...formik?.getFieldProps("firstName")}
                            />
                            {formik?.touched?.firstName &&
                            formik?.errors?.firstName ? (
                              <div className="invalid-feedback">
                                {formik?.errors?.firstName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form_field_wrapper">
                            <label>
                              Last Name<span>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Last Name"
                              className={`form-control ${
                                formik?.touched?.lastName &&
                                formik?.errors?.lastName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="lastName"
                              id="lastName"
                              readOnly={formSubmitted}
                              {...formik?.getFieldProps("lastName")}
                            />
                            {formik?.touched?.lastName &&
                            formik?.errors?.lastName ? (
                              <div className="invalid-feedback">
                                {formik?.errors?.lastName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form_field_wrapper">
                        <label>
                          Address 1<span>*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Address 1"
                          className={`form-control ${
                            formik?.touched?.address1 && formik?.errors?.address1
                              ? "is-invalid"
                              : ""
                          }`}
                          name="address1"
                          id="address1"
                          readOnly={formSubmitted}
                          {...formik?.getFieldProps("address1")}
                        />
                        {formik?.touched?.address1 && formik?.errors?.address1 ? (
                          <div className="invalid-feedback">
                            {formik?.errors?.address1}
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form_field_wrapper">
                            <label>
                              State<span>*</span>
                            </label>
                            <select
                              name="stateCode"
                              id="stateCode"
                              value={formik?.values?.stateCode}
                              onChange={handleStates}
                              disabled={formSubmitted}
                              className={`form-control ${
                                formik?.touched?.stateCode &&
                                formik?.errors?.stateCode
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <option value="">Select State</option>
                              {stateList?.map((state) => (
                                <option
                                  key={state?.isoCode}
                                  value={state?.isoCode}
                                >
                                  {state?.name}
                                </option>
                              ))}
                            </select>
                            {formik?.touched?.stateCode &&
                            formik?.errors?.stateCode ? (
                              <div className="invalid-feedback">
                                {formik?.errors?.stateCode}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form_field_wrapper">
                            <label>
                              City<span>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="city"
                              className={`form-control ${
                                formik?.touched?.city && formik?.errors?.city
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="city"
                              // value={formik?.values?.city}
                              disabled={formSubmitted}
                              {...formik?.getFieldProps("city")}
                            />

                            {formik?.touched?.city && formik?.errors?.city ? (
                              <div className="invalid-feedback">
                                {formik?.errors?.city}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form_field_wrapper">
                            <label>
                              ZIP Code<span>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="ZIP Code"
                              className={`form-control ${
                                formik?.touched?.pinCode && formik?.errors?.pinCode
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="pinCode"
                              id="pinCode"
                              readOnly={formSubmitted}
                              {...formik?.getFieldProps("pinCode")}
                            />
                            {formik?.touched?.pinCode && formik?.errors?.pinCode ? (
                              <div className="invalid-feedback">
                                {formik?.errors?.pinCode}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <label className="custom_checkbox">
                        <input type="checkbox" name="save_info" />
                        <span className="checkmark"></span>
                        Save this information for next time
                      </label>

                      {!formSubmitted ? (
                        <button
                          className="btn btn-primary purchase"
                          type="submit"
                        >
                          PURCHASE
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={handleFormEdit}
                        >
                          edit Delivery Fields
                        </button>
                      )}
                    </form>
                  </div>

                  <div className="content_item payment_meathod">
                    <h2 className="content_title">Payment</h2>
                    <div className="content_desc">
                      All transactions are secure and encrypted.
                    </div>
                    {formSubmitted && formSubmitted ? (
                      <div style={{ maxWidth: "750px", minHeight: "200px" }}>
                        {/* initialOptionsForSubscription */}

                        {type.type == "oneTime" ? (
                          <PayPalButtons
                            fundingSource={undefined}
                            onApprove={onApprove}
                            createOrder={createOrder}
                            onError={(error) => {
                              console.error(error);
                              // Handle PayPal button error here
                              setPageLoader(false);
                              // const errorMessage = error.message;
                              // alert.error("Sorry, we're experiencing issues with your PayPal payment. Please try again later. Thank you for your patience.");
                            }}
                            onCancel={() => {
                              // alert.error("Payment canceled by user. Contact support for assistance.");
                              setPageLoader(false);
                            }}
                            onClick={(rest, actions) => {
                              setPageLoader(true);
                            }}
                          />
                        ) : (
                          <PayPalSubscriptionButtons
                            style={{
                              label: "subscribe",
                            }}
                            forceReRender={[
                              {
                                label: "subscribe",
                              },
                            ]}
                            fundingSource={undefined}
                            onApprove={onSubscriptionApprove}
                            createSubscription={createSubscription}
                            onError={(error) => {
                              console.error(error);
                              setPageLoader(false);
                              // const errorMessage = error.message;
                              // alert.error("Sorry, we're experiencing issues with your PayPal payment. Please try again later. Thank you for your patience.");
                            }}
                            onCancel={() => {
                              // alert.error("Payment canceled by user. Contact support for assistance.");
                              setPageLoader(false);
                            }}
                            onClick={() => {
                              setPageLoader(true);
                              // console.log("actions", actions);
                              // console.log("rest", rest);
                            }}
                          />
                        )}
                      </div>
                    ) : null}

                    <div className="pay_desc">
                      We authorize the payment but we do not capture until the
                      licensed provider approves the prescription.
                    </div>
                  </div>
                </div>
                <div className="col-md-6 part right_part">
                  <div className="product_card">
                    <div className="product_item">
                      <div className="pro_image">
                        <img
                          src={cartProduct?.value.productImageUrl}
                          alt="Product image"
                        />
                      </div>
                      <div className="pro_desc">
                        <h5 className="title">
                          {cartProduct?.value.productTitle}
                        </h5>
                        <Link className="View_detail" to="/app/product-details">
                          View Detail
                        </Link>
                      </div>
                    </div>
                    {cartProduct?.value.planId !== "" ? (
                      <div className="price_block">
                        <div className="item">
                          <label>Plan</label>
                          <div className="value">
                            {cartProduct?.value?.plan?.name}
                          </div>
                        </div>
                        <div className="item">
                          <label>Quantity</label>
                          <div className="value">
                            {cartProduct?.value?.quantity}
                          </div>
                        </div>
                        <div className="item">
                          <label>Plan price</label>
                          <div className="value">
                            ${cartProduct?.value?.plan?.price} / unit
                          </div>
                        </div>
                        <div className="item">
                          <label>Total price</label>
                          <div className="value">
                            ${Number(cartProduct?.value?.totalPrice).toFixed(2)}{" "}
                            / cycle
                          </div>
                        </div>
                        <div className="item">
                          <label>Total Payment Cycles</label>
                          <div className="value">
                            {cartProduct?.value?.plan?.total_cycles}
                          </div>
                        </div>

                        {/* <div className="item">
                        <label>Shipping</label>
                        <div className="value shipp_add">Enter shipping address</div>
                      </div> */}
                        <div className="item total">
                          <label>Interval</label>
                          <div className="value">
                            <span className="currency">After Every </span>
                            {cartProduct?.value.plan?.interval_count}{" "}
                            {cartProduct?.value.plan?.interval_unit}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="price_block">
                        <div className="item">
                          <label>Price</label>
                          <div className="value">
                            ${cartProduct?.value?.productPrice}
                          </div>
                        </div>
                        <div className="item">
                          <label>Quantity</label>
                          <div className="value">
                            {cartProduct?.value?.quantity}
                          </div>
                        </div>
                        <div className="item">
                          <label>Subtotal</label>
                          <div className="value">
                            ${cartProduct?.value?.totalPrice}
                          </div>
                        </div>
                        {/* <div className="item">
                        <label>Shipping</label>
                        <div className="value shipp_add">Enter shipping address</div>
                      </div> */}
                        <div className="item total">
                          <label>Total</label>
                          <div className="value">
                            <span className="currency">USD</span>$
                            {cartProduct?.value?.totalPrice}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
