import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

import InputLabel from '@mui/material/InputLabel'                                            
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { HiSearch } from 'react-icons/hi'
import { useFormik } from 'formik'
import { AddKitSchema } from '../../validations/validation'
import { fetchBlob, fetchData, SubmitData } from '../../services/apiService'
import useAlertUtils, { getCookie } from '../../utils/common.utils'
import { decryptedData, encryptData, makeEncryptionData } from '../../services/encryptionDecription.service'
import Layout from './Layouts/layout'


function Dashboard() {

 
    const [results, setResults] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const limit = 10;

  const [input, setInput] = useState('')
  const [pageLoader, setPageLoader] = useState(false)
  const [reportStatus, setReportStatus] = useState('')
  const [message, setMessage] = useState()

  const { alertSuccess, alertError } = useAlertUtils();
  const [resultData, setResultData] = useState([]);

  const [data, setData] = useState({
    kitId: 'J9H6O9V',
    dob_day: 14,
    dob_year: 1974,
    dob_month: 6
  })

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    formik.setValues({ ...data });
    setMessage()

    setOpen(true)
  }
  const handleClose = () => {
    setMessage()
    setOpen(false)
  }

  const setForm = () => {
    formik.setValues({ ...data });
  }

  const formik = useFormik({
    initialValues: data,
    validationSchema: AddKitSchema,
    onSubmit: async (values) => {


      const dob = values.dob_year + "-" + values.dob_month + "-" + values.dob_day;
      values.dob = dob;
      delete values.dob_year;
      delete values.dob_month;
      delete values.dob_day;

      const encryptedData = makeEncryptionData(values);
      const response = await SubmitData("/api/zrt/kit/retrieve", encryptedData);

      if (response?.success) {
         resultData.push(response?.data);
        setResultData(resultData);
        
        alertSuccess(response?.message);
        setOpen(false)
      } else {
        setForm();
        setMessage(response?.message);
      }
    }

  });

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    resulFunc();
};


const resulFunc = async (obj) => {

  let results = await fetchData("/api/zrt/kits?page="+currentPage+"&limit="+limit+"&search="+btoa(encryptData(input))+"&reportStatus="+encryptData(obj!=undefined? obj.reportStatus : '' ));
    setResultData(results?.data?.data);
    setPageCount(results?.data?.totalPages);
};


  useEffect(() => {
    resulFunc()
  },[])

   const downloadResult=async(kitId)=>{
     let pdf =await fetchBlob("/api/zrt/result/pdf/"+encryptData(kitId));
         const blob = new Blob([pdf.data], { type: 'application/pdf' })

     const url = URL.createObjectURL(blob);
     
    // Setting various property values
    let alink = document.createElement('a')
    alink.href = url
    alink.download = kitId + '-results.pdf'
    alink.click()
   
     
   }


    //Search Functionality
  function searchFunc(val) {
    resulFunc();
  }


  const filterChange = (event) => {
    setInput('')
    setReportStatus(event.target.value)
    resulFunc({reportStatus:event.target.value})
  }

  return (
    <>
    <Layout>
      <div className='main_content'>
        <div className='container'>
          <div className='main_block'>
            <div className='top_bar'>
              <div className='left_part'>
                <div className='main_title'>All Results</div>
              </div>

              <div className='right_part'>
                <div className='right_part_inner'>



                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                      <InputLabel id='demo-simple-select-label'>Report Status</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={reportStatus}
                        label='Report Status'
                        onChange={filterChange}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        <MenuItem value={'Ready'}>Ready</MenuItem>
                        <MenuItem value={'Not Ready'}>Not Ready</MenuItem>
                      </Select>
                    </FormControl>



                  <div className='search_bar'>
                    <div className='input-group'>
                      <input
                        type='search'
                        value={input}
                        onInput={(e) => setInput(e.target.value)}
                        className='form-control'
                        placeholder='Search by KitId'
                        aria-label='Search'
                      />
                      <button className='btn search-btn' onClick={searchFunc}>
                        <HiSearch size={20} />
                      </button>
                    </div>
                  </div>


         

                </div>
              </div>
            </div>


            <div class='bottom_bar'>
              <div class='table_wrapper dashboard'>
                <div class='table_inner'>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Kit</TableCell>
                          <TableCell align='right'>Patient Name</TableCell>

                          <TableCell align='right'>Sample Received</TableCell>
                          <TableCell>Report Status</TableCell>
                          <TableCell align='right'>Report Date</TableCell>
                          <TableCell align='right'>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {resultData?.map((row) => (
                          <TableRow key={resultData.kitId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align='right'>
                              <span className='mob_head'>Kit</span>
                              {row.kitId}
                            </TableCell>

                            <TableCell align='right'>
                              <span className='mob_head'>Patient Name</span>
                              {decryptedData(row.User.firstName)}                            {decryptedData(row.User.lastName)}
                            </TableCell>


                            <TableCell align='right'>
                              {' '}
                              <span className='mob_head'>Sample Received</span>
                              {decryptedData(row.samplesReceived)}
                            </TableCell>
                            <TableCell align='right'>
                              {' '}
                              <span className='mob_head'>Report Status</span> {row.status}{' '}
                            </TableCell>

                            <TableCell align='right'>
                              <span className='mob_head'>Report Date</span>
                              {decryptedData(row.ReportDate)}
                            </TableCell>
                            <TableCell align='right'>
                              {/* check if the results are ready for download  */}
                              <span className='mob_head'>Action</span>
                              {row.status == 'Ready' ? (
                                
                                <div className='icon_group'>
                                  <Link className='action_link' to={'/app/report/' + btoa(encryptData(row.kitId))}>
                                    View Reports
                                  </Link>


                                  <Link className='action_link' onClick={() => downloadResult(row.kitId)}>
                                      Download
                                    </Link>
                   

                                </div>
                              ) : (
                                <div className='icon_group'></div>
                              )}

                                

                            </TableCell>
                          </TableRow>
                        ))}


                        {resultData?.length === 0 ? (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0
                              }
                            }}
                          >
                            <TableCell colSpan={6}>
                              <div className=' notice'>
                                <div className='alert'>
                                  <span className='alertText'>No Records Found, add kit using the Add Kit button</span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )}

            
                      </TableBody>
                    </Table>
                  </TableContainer>



                </div>
              </div>
            </div>

          </div>
        </div>


      </div>


          <ReactPaginate
            breakLabel='...'
            nextLabel='next >'
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel='< previous'
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName='pagination justify-content-center'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            activeClassName='active'
          />
  

      </Layout>

    </>
  )
}

export default Dashboard
