import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Zoom from 'react-medium-image-zoom'
import { FaArrowLeft } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './PatientDetails.scss'
import 'react-medium-image-zoom/dist/styles.css'
import Layout from '../Layouts/layout'
import { fetchData } from '../../../services/apiService'
import { decryptedData } from '../../../services/encryptionDecription.service'

const PatientDetails = () => {
  const [value, setValue] = useState('1')
  const [pageLoader, setPageLoader] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams();
  const [personalInfo, setPersonalInfo] = useState();
  const [medicalHistoryData, setMedicalHistoryData] = useState();
  const [healthGoalsData, setHealthGoalsData] = useState();
  const [documentsData, setDocumentsData] = useState();

  const role = localStorage.getItem('uR')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  const personalInformationData = async () => {
    const infoData = await fetchData("/api/intakeForms/pearsonalInformation?userId=" + id);
    setPersonalInfo(infoData?.data);
  }

  const pastMedicalHistory = async () => {
    const medicalHistoryData = await fetchData("/api/intakeForms/medical/history?userId=" + id);
    setMedicalHistoryData(medicalHistoryData?.data);
  }

  const questionaires = async () => {
    const questionaries = await fetchData("/api/intakeForms/questionnaires?userId=" + id);
    setHealthGoalsData(questionaries?.data);
  }


  const documentsFunc = async () => {
    const documents = await fetchData("/api/intakeForms/documents?userId=" + id);
    setDocumentsData(documents?.data);
  }

  useEffect(() => {
    personalInformationData();
    pastMedicalHistory();
    questionaires();
    documentsFunc()
  }, []);



  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div>
      <Layout>

        <div className='main_content patient_details'>
          <div className='container'>
            <div className='back_link'>
              <Link className='action_link' onClick={handleGoBack}>
                <FaArrowLeft />
                Back
              </Link>
            </div>

            {/* Displaying Personal Info, History, Questions, and Documents tabs */}
            <div className='main_block'>
              <div className='main_title'>Patient Name</div>
              <div className='sub_title uppercase'>John Doe</div>

              <div className='tabs'>
                <TabContext value={value}>
                  <Box>
                    <TabList onChange={handleChange} aria-label='patient tabs example'>
                      <Tab label='Personal Info' value='1' />
                      <Tab label='History' value='2' />
                      <Tab label='Questions' value='3' />
                      <Tab label='Documents' value='4' />
                    </TabList>
                  </Box>

                  {/* Personal Info Tab Panel */}
                  <TabPanel value='1'>
                    <div className='tabpanel_inner personal_info'>
                      <div className='tab_data'>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Legal First Name</label>
                            <div className='value'>{decryptedData(personalInfo?.firstName)}</div>
                          </li>
                          <li className='item'>
                            <label>Legal Last Name</label>
                            <div className='value'>{decryptedData(personalInfo?.lastName)}</div>
                          </li>
                          <li className='item'>
                            <label>Gender</label>
                            <div className='value'>{decryptedData(personalInfo?.gender)}</div>
                          </li>
                          <li className='item'>
                            <label>Date of Birth</label>
                            <div className='value'>{decryptedData(personalInfo?.dob)}</div>
                          </li>
                          <li className='item'>
                            <label>Weight</label>
                            <div className='value'>{decryptedData(personalInfo?.weight)}</div>
                          </li>
                          <li className='item'>
                            <label>Height</label>
                            <div className='value'>{decryptedData(personalInfo?.height)}</div>
                          </li>
                          <li className='item'>
                            <label>Email ID</label>
                            <div className='value'>{decryptedData(personalInfo?.email)}</div>
                          </li>
                          <li className='item'>
                            <label>Address 1</label>
                            <div className='value'>{decryptedData(personalInfo?.address1)}</div>
                          </li>
                          <li className='item'>
                            <label>Address 2</label>
                            <div className='value'>{decryptedData(personalInfo?.address2)}</div>
                          </li>
                          <li className='item'>
                            <label>Country</label>
                            <div className='value'>{decryptedData(personalInfo?.country)}</div>
                          </li>
                          <li className='item'>
                            <label>City</label>
                            <div className='value'>{decryptedData(personalInfo?.city)}</div>
                          </li>
                          <li className='item'>
                            <label>State</label>
                            <div className='value'>{decryptedData(personalInfo?.state)}</div>
                          </li>
                          <li className='item'>
                            <label>Zip Code</label>
                            <div className='value'>{decryptedData(personalInfo?.zipCode)}</div>
                          </li>
                          <li className='item'>
                            <label>Mobile Phone</label>
                            <div className='value'>{decryptedData(personalInfo?.mobileNumber)}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>

                  {/* History Tab Panel */}
                  <TabPanel value='2'>
                    <div className='tabpanel_inner history'>
                      <div className='tab_data'>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Past Medical History</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.pastMedicalHistory)}</div>
                          </li>
                          <li className='item'>
                            <label>Past Surgical History</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.pastSurgicalHistory)}</div>
                          </li>
                          <li className='item'>
                            <label>Current Medications</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.currentMedications)}</div>
                          </li>
                          <li className='item'>
                            <label>Current Supplements</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.currentSupplements)}</div>
                          </li>
                          <li className='item'>
                            <label>Allergies</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.allergies)}</div>
                          </li>
                        </ul>
                        <h3 className='tab_title'>Health Screenings</h3>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Colonoscopy</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.colonoscopy)}</div>
                          </li>
                          <li className='item'>
                            <label>Prostate Exam</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.prostateExam)}</div>
                          </li>
                          <li className='item'>
                            <label>Mammogram</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.mammogram)}</div>
                          </li>
                          <li className='item'>
                            <label>Pap Smear</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.papSmear)}</div>
                          </li>
                        </ul>
                        <h3 className='tab_title'>Physician Question</h3>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Primary Care Physician</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.currentPrimaryCarePhys)}</div>
                          </li>
                          <li className='item'>
                            <label>Physician Telephone</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.physTelephone)}</div>
                          </li>
                          <li className='item'>
                            <label>Have you had a phy
                              sical in the last year?</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.physLastYear)}</div>
                          </li>
                        </ul>
                        <h3 className='tab_title'>Family History</h3>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Cardiovascular Disease</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.haStHbPEtc)}</div>
                          </li>
                          <li className='item'>

                            <label>Diabetes, Thyroid or Other Endocrine Disorder</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.diaThEnDis)}</div>
                          </li>
                          <li className='item'>
                            <label>Cancer</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.cancer)}</div>
                          </li>
                          <li className='item'>
                            <label>Other Illnesses</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.otherIllnesses)}</div>
                          </li>
                        </ul>
                        <h3 className='tab_title'>Lifestyle Information</h3>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Used Tobacco Products</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.tobacco)}</div>
                          </li>
                          <li className='item'>
                            <label>Drink Alcohol</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.drink)}</div>
                          </li>
                          <li className='item'>
                            <label>Use Recreational Drugs</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.recreDrugs)}</div>
                          </li>
                          <li className='item'>
                            <label>Exercise Regularly</label>
                            <div className='value'>{decryptedData(medicalHistoryData?.excercise)}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>

                  {/* Questions Tab Panel */}
                  <TabPanel value='3'>
                    <div className='tabpanel_inner questions'>
                      <div className='tab_data'>
                        <ul className='item_wrapper'>
                          <li className='item'>
                            <label>Health Goals</label>
                            <div className='value'>  {healthGoalsData?.weightLoss === 1 && (
                              "Weight Loss, "
                            )}
                              {healthGoalsData?.sleep === 1 && (
                                "Sleep, "
                              )}
                              {healthGoalsData?.energy === 1 && (
                                "Energy, "
                              )}
                              {healthGoalsData?.bodyComposition === 1 && (
                                "Body Composition, "
                              )}
                              {healthGoalsData?.stress === 1 && (
                                "Stress, "
                              )}
                              {healthGoalsData?.muscleMass === 1 && (
                                "Muscle Mass, "
                              )}
                              {healthGoalsData?.libido === 1 && (
                                "Libido (Sex Drive), "
                              )}
                              {healthGoalsData?.mentalAcuity === 1 && (
                                "Mental Acuity (Focus), "
                              )}
                              {healthGoalsData?.memory === 1 && (
                                "Memory, "
                              )}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>

                  {/* Documents Tab Panel */}
                  <TabPanel value='4'>
                    <div className='tabpanel_inner documents'>
                      <div className='tab_data'>
                        <div className='doc_item_wrapper'>
                          <div className='doc_item'>
                            <div className='doc_item_inner'>
                              <h6 className='doc_title'>Driving License</h6>
                              <Zoom>
                                <img
                                  src={`/home/priyanshuprajapati/hrt_main/hrt_backend/public/uploads/${documentsData?.document_first}`}
                                  alt='Document'
                                  height={150}
                                  width={200}
                                  className='document_image'
                                />

                              </Zoom>
                            </div>
                          </div>


                          <div className='doc_item'>
                            <div className='doc_item_inner'>
                              <h6 className='doc_title'>Driving License</h6>
                              <Zoom>
                                <img
                                  src={`/home/priyanshuprajapati/hrt_main/hrt_backend/public/uploads/${documentsData?.document_second}`}
                                  alt='Document'

                                  height={150} width={200}
                                  className='document_image'
                                />
                              </Zoom>
                            </div>
                          </div>

                          <div className='doc_item'>
                            <div className='doc_item_inner'>
                              <h6 className='doc_title'>Driving License</h6>
                              <Zoom>
                                <img
                                  src={`http://127.0.0.1:3000/public/uploads/${documentsData?.selfie}`}
                                  alt='Document' height={150} width={200}
                                  className='document_image'
                                />
                              </Zoom>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </div>
        </div>

      </Layout>




    </div>
  )
}

export default PatientDetails
