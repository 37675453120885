import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitData } from "../../services/apiService";
import useAlertUtils, { setCookie } from "../../utils/common.utils";

export default function EmailVerification() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlertUtils();

  useEffect(() => {
    const verifyEmail = async () => {
      localStorage.setItem("token", token);
      const response = await SubmitData("/api/users/verify/account", {});

      if (response?.success) {
        alertSuccess(response?.message);
      } else {
        alertError(response?.message);
      }
      navigate("/");
    };

    verifyEmail();
  }, [token, navigate, alertSuccess, alertError]);

  return <></>;
}
