import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { HiSearch } from "react-icons/hi";
import useAlertUtils, { getCookie } from "../../../utils/common.utils";
import { fetchData, deleteData } from "../../../services/apiService";
import ReactPaginate from "react-paginate";

import {
  decryptedData,
  encryptData,
  makeEncryptionData,
} from "../../../services/encryptionDecription.service";
import Layout from "../Layouts/layout";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

function MyOrder() {
  const toggle = () => setOpenCModal(!openCModal);

  const closeBtn = (
    <button className="btn " onClick={toggle} type="button">
      &times;
    </button>
  );
  const token = getCookie("token");
  const navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(false);
  const [status, setStatus] = useState(0);
  const [results, setResults] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  const [input, setInput] = useState("");
  const [reportStatus, setReportStatus] = useState("");
  const [message, setMessage] = useState();

  const { alertSuccess, alertError } = useAlertUtils();
  const [resultData, setResultData] = useState([]);
  const [openCModal, setOpenCModal] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCatalogId, setSelectedCatalogId] = useState(null);

  const fetchOrders = async (status = 0) => {
    let results = await fetchData(
      `/api/catalog/order/getpatientOrder?page=${currentPage}&status=${status}&limit=${limit}&search=${btoa(
        encryptData(input)
      )}`
    );
    setResultData(results?.data?.data);
    setPageCount(results?.data?.totalPages);
  };

  const closeCModal = () => {
    setOpenCModal(false);
  };

  const handleStatusChange = (e) => {
    const orderstatus = e.target.value || 0;
    setStatus(orderstatus);
    fetchOrders(orderstatus);
    // setStatus(e.target.value)
    // setPageLoader(true)
    // getOrders(e.target.value, currentPage + 1)
  };

  const handleModel = (orderID, type, paymentType) => {
    setOrderID(orderID);
    setOrderType(type);
    setPaymentType(paymentType);
    setOpenCModal(true);
    setOpen(true);
  };

  const handleOrderApproval = async (orderId, type) => {
    // try {
    //   setPageLoader(true);
    //   if (type === 'SUBSCRIPTION') {
    //     await subscriptionOrderApproval(orderId);
    //   } else {
    //     await orderApproval(orderId);
    //   }
    //   alert.success('Order approved successfully');
    //   // Fetch or refresh the order list here if needed
    // } catch (err) {
    //   alert.error('Order approval failed');
    // } finally {
    //   setOpenCModal(false);
    //   setPageLoader(false);
    // }
  };

  const handleOrderCancellation = async (orderId, type) => {
    // try {
    //   setPageLoader(true);
    //   if (type === 'SUBSCRIPTION') {
    //     await subscriptionOrderCancelled(orderId);
    //   } else {
    //     await orderCancelled(orderId);
    //   }
    //   alert.success('Order cancelled successfully');
    //   // Fetch or refresh the order list here if needed
    // } catch (err) {
    //   alert.error('Order cancellation failed');
    // } finally {
    //   setOpenCModal(false);
    //   setPageLoader(false);
    // }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, input]);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
  };

  // Search Functionality
  function searchFunc() {
    fetchOrders(); // Call fetchOrders to apply the search input
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Or use any other formatting method you prefer
  };

  const filterChange = (event) => {
    setInput("");
    setReportStatus(event.target.value);
  };

  const handleDelete = async () => {
    try {
      await deleteData(`/api/catalog/delete/${selectedCatalogId}`, "DELETE"); // Replace with actual delete API call
      alertSuccess("Catalog deleted successfully");
      setOpen(false);
      fetchOrders(); // Refresh the list after deletion
    } catch (error) {
      alertError("Error deleting catalog");
    }
  };

  const handleView = (id) => {
    navigate(`/app/view-order/${id}`);
  };

  return (
    <>
      <Header />
      <div className="main_content">
        <div className="container">
          <div className="main_block">
            <div className="top_bar">
              <div className="left_part">
                <div className="main_title">Patient Order</div>
              </div>
              <div
                className="right_part"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="search_bar" style={{ marginRight: "20px" }}>
                  <div className="input-group">
                    <input
                      type="search"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      className="form-control"
                      placeholder="Search by Product"
                      aria-label="Search"
                    />
                    <button className="btn search-btn" onClick={searchFunc}>
                      <HiSearch size={20} />
                    </button>
                  </div>
                </div>
                <div className="status_dropdown">
                  <select
                    onChange={handleStatusChange}
                    className="form-control"
                    value={status}
                  >
                    <option value={0}>ALL</option>
                    <option value={1}>PENDING</option>
                    <option value={2}>APPROVED</option>
                    <option value={3}>COMPLETED</option>
                    <option value={4}>CANCELLED</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="table_wrapper dashboard">
              <div className="table_inner">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SNo.</TableCell>
                        <TableCell align="right">Order ID</TableCell>
                        <TableCell align="right">Patient</TableCell>
                        <TableCell align="right">Product Name</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Payment Type</TableCell>
                        <TableCell align="right">Payment Status</TableCell>
                        <TableCell align="right">Order Status</TableCell>
                        <TableCell align="right">Order Date</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resultData?.length > 0 &&
                        resultData?.map((row, index) => (
                          <TableRow key={row?.id}>
                            <TableCell align="right">{index + 1}</TableCell>
                            <TableCell align="right">{row?.orderId}</TableCell>
                            <TableCell align="right">
                              {row?.firstName}
                            </TableCell>
                            <TableCell align="right">
                              {row?.productTitle}
                            </TableCell>
                            <TableCell align="right">{row?.quantity}</TableCell>
                            <TableCell align="right">{row?.total}</TableCell>
                            <TableCell align="right">
                              {row?.type ? row?.type : "ONE TIME"}
                            </TableCell>
                            <TableCell>
                              <span
                                className={
                                  row?.intent === "COMPLETED" ||
                                  row?.intent === "APPROVED"
                                    ? "badge bg-success"
                                    : row?.intent === "VOID" ||
                                      row?.intent === "CANCELLED"
                                    ? "badge bg-danger"
                                    : "badge bg-warning"
                                }
                              >
                                {row?.intent}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                className={
                                  row?.status === 2
                                    ? "badge bg-success"
                                    : row?.status === 1 || row?.status === 3
                                    ? "badge bg-warning"
                                    : "badge bg-danger"
                                }
                              >
                                {row?.status === 1
                                  ? "PENDING"
                                  : row?.status === 2
                                  ? "APPROVED"
                                  : row?.status === 3
                                  ? "COMPLETED"
                                  : row?.status === 4
                                  ? "CANCELLED"
                                  : "UNKNOWN"}
                              </span>
                            </TableCell>
                            <TableCell>
                              {formatTimestamp(row?.orderCreatedAt)}
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Button
                                  variant="contained"
                                  onClick={() => handleView(row?.id)}
                                >
                                  <span>View</span>
                                </Button>
                              </Box>
                            </TableCell>

                            {/* {row?.status === 'PENDING' ? (
                            <TableCell style={{ display: "flex" }}>
                              <Box sx={{ '& button': { m: 1 } }}>
                                <Button
                                  variant='contained'
                                  size='small'
                                  startIcon={<CheckCircleIcon />}
                                  onClick={() => handleModel(row?.orderId, 'capture', row?.type)}
                                >
                                  Approve
                                </Button>
                              </Box>
                              <Box sx={{ '& button': { m: 1 } }}>
                                <Button
                                  variant='contained'
                                  size='small'
                                  startIcon={<CancelIcon />}
                                  onClick={() => handleModel(row?.orderId, 'cancel', row?.type)}
                                >
                                  Reject
                                </Button>
                              </Box>
                            </TableCell>
                          ) : null} */}
                          </TableRow>
                        ))}
                      {resultData?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={11}>
                            <div className="notice">
                              <div className="alert">
                                <span className="alertText">
                                  No Records Found, add kit using the Add Kit
                                  button
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={open} backdrop="static">
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>
          <ModalBody>
            {orderType == "capture"
              ? " Are you sure you want to approve this order and continue payment capture process?"
              : "Are you sure you want to cancel this order?"}
          </ModalBody>
        </ModalBody>
        <ModalFooter>
          {orderType == "capture" ? (
            <>
              <button
                className="btn btn-info text-light"
                disabled={pageLoader}
                onClick={() => {
                  handleOrderApproval(orderID, paymentType);
                }}
              >
                Approved
              </button>

              <button
                className="btn btn-danger text-light"
                onClick={() => {
                  setOpen(false);
                }}
              >
                close
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-info text-light"
                disabled={pageLoader}
                onClick={() => {
                  handleOrderCancellation(orderID, paymentType);
                }}
              >
                Cancel Order
              </button>

              <button
                className="btn btn-danger text-light"
                onClick={() => {
                  setOpen(false);
                }}
              >
                close
              </button>
            </>
          )}
        </ModalFooter>
      </Modal>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={2}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
      <Footer />
    </>
  );
}

export default MyOrder;
