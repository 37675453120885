import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

function generateUniqueIV() {
 
   const array = new Uint8Array(16);
    // crypto.getRandomValues(array);   
    return CryptoJS.enc.Hex.stringify(array); 
}


  export const cryptEncrypt = (data) => {
    try {
      const iv = generateUniqueIV(); // Generate a unique IV
  
      const encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
        iv: CryptoJS.enc.Hex.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
  
      // Combine the IV and ciphertext for storage/transmission
      const combinedData = `${iv}:${encryptedData}`; // Concatenate IV and ciphertext
  
      return combinedData;
    } catch (err) {
     return null;
    }
  };
  
export const encryptData= (value)=>{
  const data=  cryptEncrypt(value);
  return data;
}

export const makeEncryptionData = (values) => {
    const encryptedJson = {};
    
    for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
            const value = values[key];
            const encryptedValue = cryptEncrypt(value);
            encryptedJson[key] = encryptedValue;
        }
    }

    return encryptedJson;
};




export const decryptedData  = (combinedData) => {
  try {
    if (!combinedData) {
      throw new Error('Missing encrypted data');
    }

    const iv = combinedData.split(":")[0]; 
    const encryptedData = combinedData.split(":")[1]; 

    const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted;
  } catch (err) {

    return null; // Or handle the error differently (e.g., throw an exception)
  }
};

export const makeDecryptionData = (values) => {
  const decryptedJson = {};
  
  for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          const decryptedValue = decryptedData(value);
          decryptedJson[key] = decryptedValue;
      }
  }

  return decryptedJson;
};
