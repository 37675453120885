import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Report/Report.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { Chart } from 'react-charts'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import LinearProgress from "@mui/material/LinearProgress";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CommentBankSharp } from "@mui/icons-material";
import { decryptedData } from "../../services/encryptionDecription.service";
import { fetchData } from "../../services/apiService";
import { femaleSymptoms } from "../../data/symptomCategoriesFemale";
import { maleSymptoms } from "../../data/symptomCategoriesMale";

function valueLabelFormat(value) {
  const units = ["H", "L", ""];

  let unitIndex = 2;
  let scaledValue = value;

  return `${scaledValue} ${units[unitIndex]}`;
}

function calculateValue(value) {
  return value;
}

const Report = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [rows, setRows] = useState([]);

  //For male and Female categories
  const [totalSymptoms, setTotalSymptoms] = useState(0);
  const [arrSymptomCate, setArrSymptomCate] = useState("");
  const [finalCategories, setFinalCategories] = useState("");
  const [userRole, setUserRole] = useState("");
  //States for actual values
  const [estProDef, setEstProDef] = useState(0);
  const [estDomiProDef, setEstDomiProDef] = useState(0);
  const [lowAndDHEA, setLowAndDHEA] = useState(0);
  const [highAndDHEA, setHighAndDHEA] = useState(0);
  const [hypometa, setHypometa] = useState(0);
  const [lowCori, setLowCori] = useState(0);
  const [highCori, setHighCori] = useState(0);
  const [metaSyndro, setMetaSyndro] = useState(0);

  //States for constant values
  const [totalEstProDef, setTotalEstProDef] = useState(0);
  const [totalestDomiProDef, setTotalEstDomiProDef] = useState(0);
  const [totalelowAndDHEA, setTotalLowAndDHEA] = useState(0);
  const [totalehighAndDHEA, setTotalHighAndDHEA] = useState(0);
  const [totalehypometa, setTotalHypometa] = useState(0);
  const [totalelowCori, setTotalLowCori] = useState(0);
  const [totalehighCori, setTotalHighCori] = useState(0);
  const [totalemetaSyndro, setTotalMetaSyndro] = useState(0);
  const [test, setTest] = useState([]);
  const kitIdsLoader = async (kitId) => {
    try {
      let apiRes = await fetchData("/api/zrt/kits/" + kitId);
      var genderofUser = decryptedData(apiRes?.data?.gender);
      var symptoms = genderofUser == "female" ? femaleSymptoms : maleSymptoms;

      Object.keys(symptoms).forEach((item) => {
        switch (item) {
          case "Metabolic_Syndrome":
            setTotalMetaSyndro(symptoms[item].length);
            break;
          case "Estrogen__Progesterone_Deficiency":
            setTotalEstProDef(symptoms[item].length);
            break;
          case "High_Cortisol":
            setTotalHighCori(symptoms[item].length);
            break;
          case "High_Androgens___DHEA__Testosterone___":
            setTotalHighAndDHEA(symptoms[item].length);
            break;
          case "Low_Cortisol":
            setTotalLowCori(symptoms[item].length);
            break;
          case "Low_Androgens___DHEA__Testosterone___":
            setTotalLowAndDHEA(symptoms[item].length);
            break;
          case "Hypometabolism":
            setTotalHypometa(symptoms[item].length);
            break;
          case "Estrogen_Dominance__Progesterone_Deficiency":
            setTotalEstDomiProDef(symptoms[item].length);
            break;

          default:
            break;
        }
      });

      setRows(apiRes?.data);
      setTest(JSON.parse(apiRes?.data?.tests));

      setTotalSymptoms(apiRes?.data?.symptomChecklist?.length);
      var estpd = 0;
      var estdomprdef = 0;
      var lowandh = 0;
      var highanddh = 0;
      var hypo = 0;
      var lowcri = 0;
      var highcri = 0;
      var metasy = 0;

      var uniqCat = [];
      var finalValueCategories = [];

      apiRes?.data?.symptomChecklist?.forEach((symptom) => {
        //Filter unique values for checklist section
        if (uniqCat.includes(decryptedData(symptom.name))) {
        } else {
          uniqCat.push(decryptedData(symptom.name));
          finalValueCategories.push(symptom);
        }

        if (
          decryptedData(symptom.category) == "Estrogen__Progesterone_Deficiency"
        ) {
          if (decryptedData(symptom.severity) != "None") {
            estpd = estpd + 1;
          }
        } else if (
          decryptedData(symptom.category) ==
          "Estrogen_Dominance__Progesterone_Deficiency"
        ) {
          if (decryptedData(symptom.severity) != "None") {
            estdomprdef = estdomprdef + 1;
          }
        } else if (
          decryptedData(symptom.category) ==
          "Low_Androgens___DHEA__Testosterone___"
        ) {
          if (decryptedData(symptom.severity) != "None") {
            lowandh = lowandh + 1;
          }
        } else if (
          decryptedData(symptom.category) ==
          "High_Androgens___DHEA__Testosterone___"
        ) {
          if (decryptedData(symptom.severity) != "None") {
            highanddh = highanddh + 1;
          }
        } else if (decryptedData(symptom.category) == "Hypometabolism") {
          if (decryptedData(symptom.severity) != "None") {
            hypo = hypo + 1;
          }
        } else if (decryptedData(symptom.category) == "Low_Cortisol") {
          if (decryptedData(symptom.severity) != "None") {
            lowcri = lowcri + 1;
          }
        } else if (decryptedData(symptom.category) == "High_Cortisol") {
          if (decryptedData(symptom.severity) != "None") {
            highcri = highcri + 1;
          }
        } else {
          if (decryptedData(symptom.severity) != "None") {
            metasy = metasy + 1;
          }
        }
      });

      finalValueCategories.sort(function (a, b) {
        // Compare the 2 names

        if (decryptedData(a.name) < decryptedData(b.name)) return -1;
        if (decryptedData(a.name) > decryptedData(b.name)) return 1;
        return 0;
      });

      setFinalCategories(finalValueCategories);

      setEstProDef(estpd);
      setEstDomiProDef(estdomprdef);
      setLowAndDHEA(lowandh);
      setHighAndDHEA(highanddh);
      setHypometa(hypo);
      setLowCori(lowcri);
      setHighCori(highcri);
      setMetaSyndro(metasy);

      return "";
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const kitId = params?.kitId;

    kitIdsLoader(kitId);
  }, []);

  useEffect(() => {
    const userRole = window.localStorage.getItem("uR");
    setUserRole(decryptedData(userRole));
  }, []);

  // Tabs
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Tab1 Table
  function createData(testname, result, range) {
    return { testname, result, range };
  }

  // Tab3 Table
  function table3createData(symptomcategories, result) {
    return { symptomcategories, result };
  }
  const table3rows = [
    table3createData("Estrogen / Progesterone Deficiency", "10%"),
    table3createData("Estrogen / Progesterone Deficiency", "20%"),
  ];

  // Tab3 Symptoms Checklist Table
  function symptomschklistcreateData(symptomchklist, mild, moderate, severe) {
    return {
      symptomschklistcreateData,
      symptomchklist,
      mild,
      moderate,
      severe,
    };
  }
  const symptomschklistrows = [
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
    symptomschklistcreateData("Aches and Pains", "test", "test1", "test2"),
  ];

  //Slider
  const [slidervalue, setSliderValue] = React.useState(10);

  const sliderhandleChange = (event, slidernewValue) => {
    if (typeof slidernewValue === "number") {
      setSliderValue(slidernewValue);
    }
  };

  // Progressbar
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [rows]);

  // Saliva Testosterone Line Chart Graph
  const data = [
    {
      name: "10",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "20",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "30",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "40",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "50",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "60",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "70",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "80",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "90",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "100",
      uv: 3490,
      pv: 4300,
    },
  ];

  // Saliva DHEAS Line Chart Graph
  const dheasdata = [
    {
      name: "10",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "20",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "30",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "40",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "50",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "60",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "70",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "80",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "90",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "100",
      uv: 3490,
      pv: 4300,
    },
  ];

  // Saliva Cortisol Line Chart Graph
  const cortisoldata = [
    {
      name: "10",
      uv: 4000,
      pv: 2400,
    },
    {
      name: "20",
      uv: 3000,
      pv: 1398,
    },
    {
      name: "30",
      uv: 2000,
      pv: 9800,
    },
    {
      name: "40",
      uv: 2780,
      pv: 3908,
    },
    {
      name: "50",
      uv: 1890,
      pv: 4800,
    },
    {
      name: "60",
      uv: 2390,
      pv: 3800,
    },
    {
      name: "70",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "80",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "90",
      uv: 3490,
      pv: 4300,
    },
    {
      name: "100",
      uv: 3490,
      pv: 4300,
    },
  ];

  // console.log("row",JSON.parse(rows?.tests))
  // console.log("row",rows)

  return (
    <>
      <Header></Header>

      <div className="main_content report">
        <div className="container">
          {userRole == "patient" ? (
            <div className="back_link">
              <Link className="action_link" to="/app/lab-results">
                <FaArrowLeft />
                Lab Results
              </Link>
            </div>
          ) : (
            <div className="back_link">
              <Link className="action_link" to="/app/dashboard">
                <FaArrowLeft />
                Lab Results
              </Link>
            </div>
          )}
          {/* <div className='back_link'>
            <Link className='action_link' to='/app/lab-results'>
              <FaArrowLeft />
              Lab Results
            </Link>
          </div> */}
          <div className="main_block">
            <div className="main_title">Salivary Steroids</div>
            <div className="report_id"># 2018 08 08 222 S</div>
            <div className="tabs">
              <Box>
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab label="Test Overview" value="1" />
                      {/* <Tab label="Graphs" value="2" /> */}
                      <Tab label="Reported Symptoms" value="3" />
                      <Tab label="Lab Comments" value="4" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div className="tabpanel_inner test_overview">
                      <div className="table_wrapper">
                        <div className="table_inner">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Test Name</TableCell>
                                  <TableCell>Results</TableCell>
                                  <TableCell>Range</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {test?.length > 0
                                  ? test?.map((row) => (
                                      <TableRow
                                        key={row?.code}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {decryptedData(row?.name)}
                                        </TableCell>
                                        <TableCell>
                                          <Box sx={{ width: 250 }}>
                                            <div className="progress_slider_wrap">
                                              <Slider
                                                value={decryptedData(
                                                  row?.result
                                                )}
                                                min={decryptedData(
                                                  row?.rangeMinimum
                                                )}
                                                step={null}
                                                max={decryptedData(
                                                  row?.rangeMaximum
                                                )}
                                                scale={calculateValue}
                                                getAriaValueText={
                                                  valueLabelFormat
                                                }
                                                valueLabelFormat={
                                                  valueLabelFormat
                                                }
                                                onChange={sliderhandleChange}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="non-linear-slider"
                                              />
                                              <Typography
                                                id="non-linear-slider"
                                                gutterBottom
                                              >
                                                {decryptedData(row?.result)}{" "}
                                                {decryptedData(
                                                  row?.resultLevel
                                                )}
                                              </Typography>
                                            </div>
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          {decryptedData(row?.range)}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>

                      <div className="tab_data">
                        {/* <div className='tab_title'>Therapies</div>    */}
                        <p>
                          {" "}
                          {rows?.length != 0
                            ? decryptedData(rows?.therapies)
                            : ""}
                        </p>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel value="2">
                    <div className="tabpanel_inner graph">
                      <div className="tab_data">
                        <div className="tab_title">Graphs</div>
                        <p>
                          Disclaimer: Graphs below represent averages for
                          healthy individuals not using hormones.
                          Supplementation ranges may be higher. Please see
                          supplementation ranges and lab comments if results are
                          higher or lower than expected.
                        </p>
                      </div>

                      {/* Saliva Testosterone Graph */}
                      <div className="chart">
                        <div className="chart_inner">
                          <h2 className="chart_title">Saliva Testosterone</h2>
                          <div className="content">
                            <ResponsiveContainer>
                              <AreaChart
                                width={575}
                                height={370}
                                data={data}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 0,
                                }}
                              >
                                <defs>
                                  <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                  <linearGradient
                                    id="colorPv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#E5C140"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#E5C140"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Area
                                  type="monotone"
                                  dataKey="uv"
                                  stroke="#00B84C"
                                  fillOpacity={1}
                                  fill="url(#colorUv)"
                                />
                                <Area
                                  type="monotone"
                                  dataKey="pv"
                                  stroke="#E8001C"
                                  fillOpacity={1}
                                  fill="url(#colorPv)"
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>

                      {/* Saliva DHEAS Graph */}
                      <div className="chart">
                        <div className="chart_inner">
                          <h2 className="chart_title">Saliva DHEAS</h2>
                          <div className="content">
                            <ResponsiveContainer>
                              <AreaChart
                                width={575}
                                height={370}
                                data={dheasdata}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 0,
                                }}
                              >
                                <defs>
                                  <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                  <linearGradient
                                    id="colorPv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#E5C140"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#E5C140"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Area
                                  type="monotone"
                                  dataKey="uv"
                                  stroke="#00B84C"
                                  fillOpacity={1}
                                  fill="url(#colorUv)"
                                />
                                <Area
                                  type="monotone"
                                  dataKey="pv"
                                  stroke="#E8001C"
                                  fillOpacity={1}
                                  fill="url(#colorPv)"
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>

                      {/* Saliva Cortisol Graph */}
                      <div className="chart">
                        <div className="chart_inner">
                          <h2 className="chart_title">Saliva Cortisol</h2>
                          <div className="content">
                            <ResponsiveContainer>
                              <AreaChart
                                width={575}
                                height={370}
                                data={cortisoldata}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 0,
                                }}
                              >
                                <defs>
                                  <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#cdecbb"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                  <linearGradient
                                    id="colorPv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                  >
                                    <stop
                                      offset="5%"
                                      stopColor="#E5C140"
                                      stopOpacity={0.8}
                                    />
                                    <stop
                                      offset="95%"
                                      stopColor="#E5C140"
                                      stopOpacity={0}
                                    />
                                  </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Area
                                  type="monotone"
                                  dataKey="uv"
                                  stroke="#00B84C"
                                  fillOpacity={1}
                                  fill="url(#colorUv)"
                                />
                                <Area
                                  type="monotone"
                                  dataKey="pv"
                                  stroke="#E8001C"
                                  fillOpacity={1}
                                  fill="url(#colorPv)"
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel value="3">
                    <div className="tabpanel_inner reported_symptoms">
                      <div className="tab_data">
                        <div className="tab_title">
                          Patient Reported Symptoms
                        </div>
                        <p>
                          <span>Disclaimer:</span> Symptom Categories below show
                          percent of symptoms self-reported by the patient
                          compared to total available symptoms for each
                          category. For detailed information on category
                          breakdowns, go to www.zrtlab.com/patient-symptoms.
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table_wrapper patient_symptoms">
                            <div className="table_inner">
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Symptom Categories</TableCell>
                                      <TableCell>Results</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Estrogen/Progesterone Deficiency
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (estProDef / totalEstProDef) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (estProDef / totalEstProDef) *
                                                  100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Metabolic Syndrome
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (metaSyndro / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (metaSyndro / totalSymptoms) *
                                                  100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Low Androgens(DHEA/Testosterone)
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (lowAndDHEA / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (lowAndDHEA / totalSymptoms) *
                                                  100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        High Androgens(DHEA/Testosterone)
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (highAndDHEA / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (highAndDHEA / totalSymptoms) *
                                                  100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Estrogen Dominance/Progesterone
                                        Deficiency
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (estDomiProDef / totalSymptoms) *
                                              100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (estDomiProDef /
                                                  totalSymptoms) *
                                                  100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Hypometabolism
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (hypometa / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (hypometa / totalSymptoms) * 100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        Low Cortisol
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (lowCori / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (lowCori / totalSymptoms) * 100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="table3rows"
                                      >
                                        High Cortisol
                                      </TableCell>
                                      <TableCell>
                                        <div className="result">
                                          {Math.round(
                                            (highCori / totalSymptoms) * 100
                                          )}
                                          %
                                          <Box sx={{ width: "30%" }}>
                                            <LinearProgress
                                              variant="determinate"
                                              value={Math.round(
                                                (highCori / totalSymptoms) * 100
                                              )}
                                            />
                                          </Box>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table_wrapper symptoms_checklist">
                        <div className="table_inner">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Symptom Checklist</TableCell>
                                  <TableCell align="center">Mild</TableCell>
                                  <TableCell align="center">Moderate</TableCell>
                                  <TableCell align="center">Severe</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows?.length}
                                {/* {rows.length != 0
                                  ? finalCategories.map((row) => (
                                    <TableRow key={row.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell className='active' component='th' scope='symptomschklistrows'>
                                        {decryptedData(row.name)}

                                        {decryptedData(row.severity) == 'None' ? (
                                          <div className='box_content_wrapper'>
                                            <span className='normal_box box_content'></span>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <span className='tickmark mild'></span>
                                        {decryptedData(row.severity) == 'Mild' ||
                                          decryptedData(row.severity) == 'Moderate' ||
                                          decryptedData(row.severity) == 'Severe' ? (
                                          <div className='box_content_wrapper'>
                                            <span className='mild_box box_content'></span>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <span className='tickmark moderate'></span>
                                        {decryptedData(row.severity) == 'Moderate' || decryptedData(row.severity) == 'Severe' ? (
                                          <div className='box_content_wrapper'>
                                            <span className='moderate_box box_content'></span>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <span className='tickmark severe'></span>
                                        {decryptedData(row.severity) == 'Severe' ? (
                                          <div className='box_content_wrapper'>
                                            <span className='severe_box box_content'></span>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                  : ''} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel value="4">
                    <div className="tabpanel_inner lab_comments">
                      <div className="tab_data">
                        <div className="tab_title">Lab Comments</div>
                        <p>
                          {rows?.length != 0
                            ? decryptedData(rows?.comments)
                            : ""}
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Report;
