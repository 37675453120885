import React, { useState } from 'react'
import { useEffect } from 'react'
import '../History/History.scss'
import FormGroup from '@mui/material/FormGroup'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { historySchema } from '../../../validations/validation'
import { makeDecryptionData, makeEncryptionData } from '../../../services/encryptionDecription.service'
import { fetchData, SubmitData } from '../../../services/apiService'
import { updateTab } from '../../../redux/slices/tab.slices'
import { useDispatch } from 'react-redux'
import useAlertUtils from "../../../utils/common.utils";

const History = (props) => {
  const [serverError, setServerError] = useState('')
  const [loader, setLoader] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const [haStHbPEtcInputFieldShow, sethaStHbPEtcInputFieldShow] = useState(false)
  const [DiaThEnDisInputFieldShow, setDiaThEnDisInputFieldShow] = useState(false)
  const [cancerInputFieldShow, setcancerInputFieldShow] = useState(false)
  const [otherIllnessesInputfieldShow, setotherIllnessesInputfieldShow] = useState(false)

  const dispatch = useDispatch();
  
  const {alertSuccess,alertError}=useAlertUtils();

  const [data, setData] = useState({
    pastmmedicalhistory: '',
    pastsurgicalhistory: '',
    currentmedications: '',
    currentsupplements: '',
    allergies: '',
    colonoscopy: '',
    prostateExam: '',
    mammogram: '',
    papSmear: '',
    curntPrimaryCarePhys: '',
    physTelephone: '',
    physLastYear: '',
    haStHbPEtc: '',
    DiaThEnDis: '',
    cancer: '',
    otherIllnesses: '',
    tobacco: '',
    drink: '',
    recreDrugs: '',
    excercise: '',
    haStHbPEtcInputField: '',
    DiaThEnDisInputField: '',
    cancerInputField: '',
    otherIllnessesInputfield: ''
  })


  const formik = useFormik({
    initialValues: data,
    validationSchema: historySchema,
    onSubmit: async (values,{resetForm}) => {
     
      const encryptedData=makeEncryptionData(values)

      if(encryptedData){
       const response = await SubmitData("/api/intakeForms/medical/history",encryptedData);
       if(response?.success){
         alertSuccess(response?.message);
         resetForm();
         dispatch(updateTab('3'));
       }else{
        alertError(response?.message);
       }
      }  

    }
  })

  //Fetch intake form data

  async function intakeFormFetchFunc() {
    return "";
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (props.tabVal == '2') {
      if (formik.initialValues.pastmmedicalhistory == '') {
        setPageLoader(true)
        intakeFormFetchFunc()
      }
    }
  }, [])


  useEffect(()=>{
    const mHistoryFunc= async()=>{
      const mHistory= await fetchData("/api/intakeForms/medical/history");
      if(mHistory.data!=undefined || mHistory.data!= null){
      const decryptedData= makeDecryptionData(mHistory?.data);
   
      
      formik.setFieldValue('allergies', decryptedData?.allergies);
      formik.setFieldValue('cancer', decryptedData?.cancer);
      formik.setFieldValue('cancerInputField', decryptedData?.cancerInputField);
      formik.setFieldValue('colonoscopy', decryptedData?.colonoscopy);
      formik.setFieldValue('currentmedications', decryptedData?.currentMedications);
      formik.setFieldValue('curntPrimaryCarePhys', decryptedData?.currentPrimaryCarePhys);
      formik.setFieldValue('currentsupplements', decryptedData?.currentSupplements);
      formik.setFieldValue('DiaThEnDis', decryptedData?.diaThEnDis);
      formik.setFieldValue('diaThEnDisInputField', decryptedData?.diaThEnDisInputField);
      formik.setFieldValue('drink', decryptedData?.drink);
      formik.setFieldValue('excercise', decryptedData?.exercise);
      formik.setFieldValue('haStHbPEtc', decryptedData?.haStHbPEtc);
      formik.setFieldValue('haStHbPEtcInputField', decryptedData?.haStHbPEtcInputField);
      formik.setFieldValue('mammogram', decryptedData?.mammogram);
      formik.setFieldValue('otherIllnesses', decryptedData?.otherIllnesses);
      formik.setFieldValue('otherIllnessesInputField', decryptedData?.otherIllnessesInputField);
      formik.setFieldValue('papSmear', decryptedData?.papSmear);
      formik.setFieldValue('pastmmedicalhistory', decryptedData?.pastMedicalHistory);
      formik.setFieldValue('pastsurgicalhistory', decryptedData?.pastSurgicalHistory);
      formik.setFieldValue('physLastYear', decryptedData?.physLastYear);
      formik.setFieldValue('physTelephone', decryptedData?.physTelephone);
      formik.setFieldValue('prostateExam', decryptedData?.prostateExam);
      formik.setFieldValue('recreDrugs', decryptedData?.recreDrugs);
      formik.setFieldValue('tobacco', decryptedData?.tobacco);
      }
    }
    mHistoryFunc();

   },[]);



  //Funtion for previous
  function t(params) {
    props.handleChange('e', '1')
  }

  function inputHideShow(e) {
    if (e.target.id == 'haStHbPEtc') {
      if (e.target.value == 'Others') {
        sethaStHbPEtcInputFieldShow(true)
      } else {
        sethaStHbPEtcInputFieldShow(false)
        formik.values.haStHbPEtcInputField = ''
      }
    }

    if (e.target.id == 'DiaThEnDis') {
      if (e.target.value == 'Others') {
        setDiaThEnDisInputFieldShow(true)
      } else {
        setDiaThEnDisInputFieldShow(false)
        formik.values.DiaThEnDisInputField = ''
      }
    }

    if (e.target.id == 'cancer') {
      if (e.target.value == 'Others') {
        setcancerInputFieldShow(true)
      } else {
        setcancerInputFieldShow(false)
        formik.values.cancerInputField = ''
      }
    }

    if (e.target.id == 'otherIllnesses') {
      if (e.target.value == 'Others') {
        setotherIllnessesInputfieldShow(true)
      } else {
        setotherIllnessesInputfieldShow(false)
        formik.values.otherIllnessesInputfield = ''
      }
    }
  }

  //For removing \n and making them add new line
  const parseLines = (value) => value.replace(/(\\n)/g, '\n')

  return (
    <>

      <form onSubmit={formik.handleSubmit}>
        <fieldset>
          <div className='form_title'>Health History Information</div>
          <div className='form_field_wrapper'>
            <label>
              Past Medical History<span className='req'>*</span>
            </label>
            <div className='field_info'>
              Please list ALL Conditions you have been diagnosed with or have been treated for in the past (Example: high blood pressure,
              high cholesterol, diabetes)
            </div>
            <textarea
              className='form-control line_wrap'
              id='pastmmedicalhistory'
              rows='4'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseLines(formik.values.pastmmedicalhistory)}
              placeholder='Please type N/A if not applicable'
            ></textarea>

            {/* If validation is not passed show errors */}
            <p className='error'>
              {formik.errors.pastmmedicalhistory && formik.touched.pastmmedicalhistory && formik.errors.pastmmedicalhistory}
            </p>
          </div>

          <div className='form_field_wrapper'>
            <label>
              Past Surgical History<span className='req'>*</span>
            </label>
            <div className='field_info'>Please list ALL Surgeries - Include Date (Example: Tonsillectomy - 1988)</div>
            <textarea
              className='form-control'
              id='pastsurgicalhistory'
              rows='4'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseLines(formik.values.pastsurgicalhistory)}
              placeholder='Please type N/A if not applicable'
            ></textarea>
            {/* If validation is not passed show errors */}
            <p className='error'>
              {formik.errors.pastsurgicalhistory && formik.touched.pastsurgicalhistory && formik.errors.pastsurgicalhistory}
            </p>
          </div>

          <div className='form_field_wrapper'>
            <label>
              Current Medications<span className='req'>*</span>
            </label>
            <div className='field_info'>Please list all medications you are currently taking, along with dose and frequency.</div>
            <textarea
              className='form-control'
              id='currentmedications'
              rows='4'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseLines(formik.values.currentmedications)}
              placeholder='Please type N/A if not applicable'
            ></textarea>
            {/* If validation is not passed show errors */}
            <p className='error'>
              {formik.errors.currentmedications && formik.touched.currentmedications && formik.errors.currentmedications}
            </p>
          </div>

          <div className='form_field_wrapper'>
            <label>
              Current Supplements<span className='req'>*</span>
            </label>
            <div className='field_info'>Please list all supplements you are currently taking, along with dose and frequency.</div>
            <textarea
              className='form-control'
              id='currentsupplements'
              rows='4'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseLines(formik.values.currentsupplements)}
              placeholder='Please type N/A if not applicable'
            ></textarea>
            {/* If validation is not passed show errors */}
            <p className='error'>
              {formik.errors.currentsupplements && formik.touched.currentsupplements && formik.errors.currentsupplements}
            </p>
          </div>

          <div className='form_field_wrapper'>
            <label>
              Allergies<span className='req'>*</span>
            </label>
            <div className='field_info'>Please list any medication allergies.</div>
            <textarea
              className='form-control'
              id='allergies'
              rows='4'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={parseLines(formik.values.allergies)}
              placeholder='Please type N/A if not applicable'
            ></textarea>
            <p className='error'>{formik.errors.allergies && formik.touched.allergies && formik.errors.allergies}</p>
          </div>
        </fieldset>

        <fieldset>
          <div className='form_title'>Health Screenings</div>

          <div className='form_group_content'>
            <div className='form_label'>
              Colonoscopy <span className='req'>*</span>
            </div>

            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='colonoscopy'
                  name='colonoscopy'
                  checked={formik.values.colonoscopy.length != 0 ? (formik.values.colonoscopy.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='colonoscopy'
                  name='colonoscopy'
                  checked={formik.values.colonoscopy.length != 0 ? (formik.values.colonoscopy.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.colonoscopy && formik.touched.colonoscopy && formik.errors.colonoscopy}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Prostate Exam <span className='req'>*</span>
            </div>

            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='prostateExam'
                  name='prostateExam'
                  checked={formik.values.prostateExam.length != 0 ? (formik.values.prostateExam.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='prostateExam'
                  name='prostateExam'
                  checked={formik.values.prostateExam.length != 0 ? (formik.values.prostateExam.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='N/A'
                  id='prostateExam'
                  name='prostateExam'
                  checked={formik.values.prostateExam.length != 0 ? (formik.values.prostateExam.includes('N/A') ? 'checked' : '') : ''}
                />
                <span> N/A</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.prostateExam && formik.touched.prostateExam && formik.errors.prostateExam}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Mammogram <span className='req'>*</span>
            </div>

            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='mammogram'
                  name='mammogram'
                  checked={formik.values.mammogram.length != 0 ? (formik.values.mammogram.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='mammogram'
                  name='mammogram'
                  checked={formik.values.mammogram.length != 0 ? (formik.values.mammogram.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='N/A'
                  id='mammogram'
                  name='mammogram'
                  checked={formik.values.mammogram.length != 0 ? (formik.values.mammogram.includes('N/A') ? 'checked' : '') : ''}
                />
                <span> N/A</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.mammogram && formik.touched.mammogram && formik.errors.mammogram}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Pap Smear <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='papSmear'
                  name='papSmear'
                  checked={formik.values.papSmear.length != 0 ? (formik.values.papSmear.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='papSmear'
                  name='papSmear'
                  checked={formik.values.papSmear.length != 0 ? (formik.values.papSmear.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='N/A'
                  id='papSmear'
                  name='papSmear'
                  checked={formik.values.papSmear.length != 0 ? (formik.values.papSmear.includes('N/A') ? 'checked' : '') : ''}
                />
                <span> N/A</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.papSmear && formik.touched.papSmear && formik.errors.papSmear}</p>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='form_field_wrapper'>
                <label>Do you have a current Primary Care Physician? If so list name:</label>
                <input
                  type='text'
                  placeholder='Physician Name'
                  className='form-control inp_text'
                  id='curntPrimaryCarePhys'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.curntPrimaryCarePhys}
                />
                <p className='error'>
                  {formik.errors.curntPrimaryCarePhys && formik.touched.curntPrimaryCarePhys && formik.errors.curntPrimaryCarePhys}
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form_field_wrapper'>
                <label>Physician's Telephone</label>
                <input
                  type='text'
                  placeholder="Physician's Telephone"
                  className='form-control inp_text'
                  id='physTelephone'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.physTelephone}
                />
                <p className='error'>{formik.errors.physTelephone && formik.touched.physTelephone && formik.errors.physTelephone}</p>
              </div>
            </div>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Have you had a physical in the last year? <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='physLastYear'
                  name='physLastYear'
                  checked={formik.values.physLastYear.length != 0 ? (formik.values.physLastYear.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='physLastYear'
                  name='physLastYear'
                  checked={formik.values.physLastYear.length != 0 ? (formik.values.physLastYear.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.physLastYear && formik.touched.physLastYear && formik.errors.physLastYear}</p>
          </div>
        </fieldset>

        <fieldset>
          <div className='form_title'>Family History</div>
          <div className='form_sub_title'>
            Does an immediate family member currently have or ever had any of the following? If yes, please check below and explain in the
            provided field:
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Cardiovascular Disease <span className='req'>*</span>
            </div>
            <div className='form_sub_label'>Heart Attack, Stroke, High Blood Pressure, etc..</div>

            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='haStHbPEtc'
                  name='haStHbPEtc'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.haStHbPEtc.length != 0 ? (formik.values.haStHbPEtc.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='haStHbPEtc'
                  name='haStHbPEtc'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.haStHbPEtc.length != 0 ? (formik.values.haStHbPEtc.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <div className='other'>
                {haStHbPEtcInputFieldShow || formik.values.haStHbPEtc.includes('Yes') ? (
                  <div className='form_field_wrapper'>
                    <br></br>
                    <small></small>
                    <input
                      onChange={formik.handleChange}
                      id='haStHbPEtcInputField'
                      name='haStHbPEtcInputField'
                      onBlur={formik.handleBlur}
                      value={formik.values.haStHbPEtcInputField}
                      placeholder='Please explain'
                      className='form-control inp_text'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.haStHbPEtc && formik.touched.haStHbPEtc && formik.errors.haStHbPEtc}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Diabetes, Thyroid or Other Endocrine Disorder <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='DiaThEnDis'
                  name='DiaThEnDis'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.DiaThEnDis.length != 0 ? (formik.values.DiaThEnDis.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='DiaThEnDis'
                  name='DiaThEnDis'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.DiaThEnDis.length != 0 ? (formik.values.DiaThEnDis.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <div className='other'>
                {DiaThEnDisInputFieldShow || formik.values.DiaThEnDis.includes('Yes') ? (
                  <div className='form_field_wrapper'>
                    <br></br>
                    <small></small>
                    <input
                      onChange={formik.handleChange}
                      id='DiaThEnDisInputField'
                      name='DiaThEnDisInputField'
                      onBlur={formik.handleBlur}
                      value={formik.values.DiaThEnDisInputField}
                      placeholder='Please explain'
                      className='form-control inp_text'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.DiaThEnDis && formik.touched.DiaThEnDis && formik.errors.DiaThEnDis}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Cancer <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='cancer'
                  name='cancer'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.cancer.length != 0 ? (formik.values.cancer.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='cancer'
                  name='cancer'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.cancer.length != 0 ? (formik.values.cancer.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <div className='other'>
                {cancerInputFieldShow || formik.values.cancer.includes('Yes') ? (
                  <div className='form_field_wrapper'>
                    <br></br>
                    <small></small>
                    <input
                      onChange={formik.handleChange}
                      id='cancerInputField'
                      name='cancerInputField'
                      onBlur={formik.handleBlur}
                      value={formik.values.cancerInputField}
                      placeholder='Please explain'
                      className='form-control inp_text'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.cancer && formik.touched.cancer && formik.errors.cancer}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Other Illnesses <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='otherIllnesses'
                  name='otherIllnesses'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.otherIllnesses.length != 0 ? (formik.values.otherIllnesses.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='otherIllnesses'
                  name='otherIllnesses'
                  onClick={(e) => inputHideShow(e)}
                  checked={formik.values.otherIllnesses.length != 0 ? (formik.values.otherIllnesses.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>

              <div className='other'>
                {otherIllnessesInputfieldShow || formik.values.otherIllnesses.includes('Yes') ? (
                  <div className='form_field_wrapper'>
                    <br></br>
                    <small></small>
                    <input
                      onChange={formik.handleChange}
                      id='otherIllnessesInputfield'
                      name='otherIllnessesInputfield'
                      onBlur={formik.handleBlur}
                      value={formik.values.otherIllnessesInputfield}
                      placeholder='Please explain'
                      className='form-control inp_text'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.otherIllnesses && formik.touched.otherIllnesses && formik.errors.otherIllnesses}</p>
          </div>
        </fieldset>

        <fieldset>
          <div className='form_title'>Lifestyle Information</div>

          <div className='form_group_content'>
            <div className='form_label'>
              Do you currently or have you ever used tobacco products? <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='tobacco'
                  name='tobacco'
                  checked={formik.values.tobacco.length != 0 ? (formik.values.tobacco.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='tobacco'
                  name='tobacco'
                  checked={formik.values.tobacco.length != 0 ? (formik.values.tobacco.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.tobacco && formik.touched.tobacco && formik.errors.tobacco}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Do you drink alcohol? <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='drink'
                  name='drink'
                  checked={formik.values.drink.length != 0 ? (formik.values.drink.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='drink'
                  name='drink'
                  checked={formik.values.drink.length != 0 ? (formik.values.drink.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.drink && formik.touched.drink && formik.errors.drink}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Do you use recreational drugs? <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='recreDrugs'
                  name='recreDrugs'
                  checked={formik.values.recreDrugs.length != 0 ? (formik.values.recreDrugs.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='recreDrugs'
                  name='recreDrugs'
                  checked={formik.values.recreDrugs.length != 0 ? (formik.values.recreDrugs.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.recreDrugs && formik.touched.recreDrugs && formik.errors.recreDrugs}</p>
          </div>

          <div className='form_group_content'>
            <div className='form_label'>
              Do you exercise regularly? <span className='req'>*</span>
            </div>
            <FormGroup>
              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='Yes'
                  id='excercise'
                  name='excercise'
                  checked={formik.values.excercise.length != 0 ? (formik.values.excercise.includes('Yes') ? 'checked' : '') : ''}
                />
                <span> Yes</span>
              </label>

              <label>
                <input
                  type='radio'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value='No'
                  id='excercise'
                  name='excercise'
                  checked={formik.values.excercise.length != 0 ? (formik.values.excercise.includes('No') ? 'checked' : '') : ''}
                />
                <span> No</span>
              </label>
            </FormGroup>
            {/* If validation is not passed show errors */}
            <p className='error'>{formik.errors.excercise && formik.touched.excercise && formik.errors.excercise}</p>
          </div>
        </fieldset>

        <hr></hr>
        <div className='primary_btn'>
          <input className='btn btn-secondary' type='button' value='previous' onClick={t}></input>
          <button type='submit' className='btn btn-secondary'>
            next
          </button>
        </div>

     
      </form>
    </>
  )
}

export default History
