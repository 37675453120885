import React, { useState, useEffect } from "react";

import FormGroup from "@mui/material/FormGroup";

import { useFormik } from "formik";

import { useParams, useNavigate } from "react-router-dom";
import uploadimg from "../../../assets/img/upload.svg";

import CloseIcon from "@mui/icons-material/Close";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Webcam from "react-webcam";

import "./Idverification.scss";

import { v4 as uuidv4 } from "uuid";

import heic2any from "heic2any";
import { fetchData, uploadMultipartData } from "../../../services/apiService";
import useAlertUtils from "../../../utils/common.utils";
import { useDispatch } from "react-redux";
import { updateTab } from "../../../redux/slices/tab.slices";

const IdVerification = (props) => {
  const [serverError, setServerError] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [data, setData] = useState({
    healthGoals: [],
    otherGoals: "",
  });

  const [checkbox, setCheckBox] = useState({
    check: "",
  });

  const navigate = useNavigate();
  const [progresspercent, setProgresspercent] = useState(0);
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCModal, setOpenCModal] = useState(false);

  const webcamRef = React.useRef(null);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedSelfie, setSelectedSelfie] = useState("");
  const [oldDocUrls, setSetOldDocUrls] = useState([]);
  const [oldSelfieUrl, setSetOldSelfieUrl] = useState("");
  const [removedFiles, setRemovedFiles] = useState("");
  const inputFile = React.useRef(null);
  const {alertSuccess,alertError}=useAlertUtils();
  const MAX_FILE_SIZE = 10240000; //10 MB
  const dispatch = useDispatch();

  const validFileExtensions = { image: ["jpg", "png", "jpeg", "heif", "heic"] };
  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  //convert iphone images to jpg formate images
  async function convertHeicToJpg(heicFile) {
    try {
      const jpegBlob = await heic2any({
        blob: heicFile,
        toType: "image/jpeg", // Convert to JPEG format
      });
      const fileName = generateRandomName(10);
      // Create a File object from the JPEG Blob with the original file name
      const jpegFile = new File([jpegBlob], `${fileName}.jpeg`, {
        type: "image/jpeg",
      });
      // You can now use the 'jpegFile' as a downloadable File
      // console.log("Converted File:", jpegFile);
      return jpegFile; // You can return the File object if needed
    } catch (error) {
      console.error("Error converting image:", error);
      return null;
    }
  }

  // function to upload Id proof images
  const handleImageChange = async (e) => {
    const maxImages = 2; // Maximum allowed images
    const filesWithFilter = Array.from(e.target.files);

    // Create a preview for each selected image
    const files = await Promise.all(
      filesWithFilter.map(async (file) => {
        if (!isValidFileType(file.name, "image")) {
          alertError(
            `Invalid file type. Supported types are: jpg, png, jpeg, heif, and heic.`
          );
          return null;
        }

        if (file.size > MAX_FILE_SIZE) {
          alertError(`File size exceeds the maximum allowed size of 10 MB.`);
          return null;
        }

        if (file.type === "image/heic" || file.type === "image/heif") {
          return await convertHeicToJpg(file);
        } else {
          return file;
        }
      })
    );

    const filteredFiles = files.filter((file) => file !== null);

    const imagePreviews = filteredFiles.map((file) =>
      URL.createObjectURL(file)
    );

    // Check if the number of selected files exceeds the maximum
    if (
      selectedImages.length >= maxImages ||
      imagePreviews.length > maxImages
    ) {
      alertError(`You can only select up to ${maxImages} images.`);
      return;
    }

    setSelectedImages([...selectedImages, ...filteredFiles]);
    setPreviewImages([...previewImages, ...imagePreviews]);
  };

  const toggle = () => setOpenCModal(!openCModal);

  const closeBtn = (
    <button className="btn " onClick={toggle} type="button">
      &times;
    </button>
  );

  // function to upload selfie images
  const handleSelfie = async (e) => {
    const file = e.target.files[0];

    if (!isValidFileType(file.name, "image")) {
      alertError(
        `Invalid file type. Supported types are: jpg, png, jpeg, heif, and heic.`
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alertError(`File size exceeds the maximum allowed size of 10 MB.`);
      return;
    }

    let filteredFile = file;

    if (file.type === "image/heic" || file.type === "image/heif") {
      filteredFile = await convertHeicToJpg(file);
    }

    // Create a preview for the selected image
    const imagePreview = URL.createObjectURL(filteredFile);

    setSelectedSelfie(filteredFile);
    setImagePreview(imagePreview);
  };

  const handleImageRemove = (index) => {
    const newSelectedImages = [...selectedImages];

    newSelectedImages.splice(index, 1);

    const newPreviewImages = [...previewImages];
    // console.log("newPreviewImages[index]",newPreviewImages[index])
    setRemovedFiles(newPreviewImages[index]);
    newPreviewImages.splice(index, 1);

    setSelectedImages(newSelectedImages);
    setPreviewImages(newPreviewImages);
  };

  const HandleSelfieUpload = () => {
    setOpenCModal(true);
  };

  const handleSelfieImageRemove = () => {
    setImagePreview("");
    setSelectedSelfie("");
  };

  // function to open webcam preview
  const webCam = () => {
    setOpenCModal(false);
    setOpenModal(true);
  };

  // function to close modal
  const closeModal = () => {
    setOpenModal(false);
  };

  // function to close confirmation modal
  const closeCModal = () => {
    setOpenCModal(false);
  };

  // function to generate random name
  const generateRandomName = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomName = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomName += characters.charAt(randomIndex);
    }

    return randomName;
  };

  // function to capture webcam image
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const fileName = generateRandomName(10);
    convertDataUrlToFile(imageSrc, `${fileName}.jpeg`);
    setOpenModal(false);
  }, [webcamRef]);

  // convert data url to file type
  const convertDataUrlToFile = (dataUrl, fileName) => {
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const convertedFile = new File([blob], fileName, { type: blob.type });
        const selfieImageSrc = URL.createObjectURL(convertedFile);

        setSelectedSelfie(convertedFile);
        setImagePreview(selfieImageSrc);
      })
      .catch((error) => {
        console.error("Error converting Data URL to file:", error);
      });
  };

  // Function to handle submit button

  // function check if camera is available or not
  async function checkCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // If the camera is accessible, set cameraAvailable to true.
      setCameraAvailable(true);
      // Don't forget to stop the stream when you're done.
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      // If an error occurs, the camera may not be available or accessible.
      setCameraAvailable(false);
    }
  }

  useEffect(() => {
    checkCamera();
  }, [openModal]);

  const formik = useFormik({
    initialValues: data,
    //validationSchema : questionsSchema,
    onSubmit: (values) => {
      setPageLoader(true);
    },
  });

  useEffect(() => {
    if (props.tabVal == "4") {
      setPageLoader(true);
    }
  }, []);

  //Funtion for previous
  function t(params) {
    props.handleChange("e", "3");
  }

  const uploadDoc = async () => {
    
    const form = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      form.append('documents', selectedImages[i]);
    }

    form.append('selfieImage', selectedSelfie);
            
    const result = await uploadMultipartData(
      "/api/intakeForms/upload/documents",
      form
    );

    if (result?.success) {
      alertSuccess(result?.message);
       dispatch(updateTab('5'));

    } else {
      alertError(result?.message);
    }
  };



  // useEffect(()=>{
  //   const documentFunc= async()=>{
  //     const questiona= await fetchData("/api/intakeForms/documents");
  //   console.log(questiona)
  //     if(questiona.data!=undefined || questiona.data!= null){
            
  //     }
  //   }
  //   documentFunc();

  //  },[]);
   
  return (
    <>                             
      <form onSubmit={formik.handleSubmit}>
        <fieldset>
          <div className="form_title">Verify Your Identity</div>

          <div className="form_group_content">
            <FormGroup>
              <div className="main_content idverify">
                <div className="container">
                  <div className="main_block">
                    <div className="title_wrapper">
                      <div className="desc">
                        Federal Law requires us to verify your identity. Please
                        upload a current picture of yourself and a personal ID
                        for verification.
                      </div>
                      {/* <div className='desc'>Next Step is to upload a selfie and a picture ID for verification</div> */}
                    </div>

                    <div className="upload_doc_section">
                      <div className="row upload_item_wrapper">
                        <div className="col-md-6 upload_item">
                          <div className="form_field_wrapper">
                            <label>
                              Upload front and back of the picture ID
                            </label>
                            <div className="upload_input">
                              <div className="user_upload_content">
                                <div className="image">
                                  <img src={uploadimg} />
                                </div>
                                <label>Drag To Upload Driving License</label>
                                <span>Browse</span>
                              </div>
                              <input
                                id="file"
                                name="file"
                                type="file"
                                className="user_upload_input"
                                accept="image/*"
                                multiple
                                onClick={(e) => {
                                  e.target.value = null; // Reset the input to allow reselection
                                }}
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                          <div className="upload_doc_item_block">
                            <div className="item_wrapper">
                              {previewImages.map((preview, index) => (
                                <div className="item">
                                  <div className="item_inner">
                                    <div className="image">
                                      <img
                                        className="upload_img"
                                        src={preview}
                                      />
                                      <span
                                        className="btn_close"
                                        onClick={() => handleImageRemove(index)}
                                      >
                                        <CloseIcon />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 upload_item">
                          <div className="form_field_wrapper">
                            <label>Upload a picture of yourself</label>
                            <div
                              className="upload_input"
                              onClick={() => HandleSelfieUpload()}
                            >
                              <div className="user_upload_content">
                                <div className="image">
                                  <img src={uploadimg} />
                                </div>
                                <label>
                                  Click to Upload a picture of yourself
                                </label>
                              </div>
                            </div>

                            <input
                              id="file"
                              name="file"
                              type="file"
                              className="user_upload_input d-none"
                              accept="image/*"
                              ref={inputFile}
                              onClick={(e) => {
                                e.target.value = null; // Reset the input to allow reselection
                              }}
                              onChange={handleSelfie}
                            />
                          </div>

                          <div className="upload_doc_item_block">
                            <div className="item_wrapper">
                              {imagePreview && (
                                <div className="item">
                                  <div className="item_inner">
                                    <div className="image">
                                      <img
                                        className="upload_img"
                                        src={imagePreview}
                                      />
                                      <span
                                        className="btn_close"
                                        onClick={() =>
                                          handleSelfieImageRemove()
                                        }
                                      >
                                        <CloseIcon />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>
        </fieldset>

        <hr></hr>
        <div className="primary_btn">
          <input
            className="btn btn-secondary"
            type="button"
            value="previous"
            onClick={t}
          ></input>

          <button
            type="button"
            className="btn btn-secondary bg-danger text-white"
            onClick={uploadDoc}
          >
            Submit
          </button>
        </div>

        {!formik.isValid ? (
          <p className="error">Rectify the errors in the form !</p>
        ) : (
          ""
        )}
        {/* Server Error */}
        {serverError == "" ? (
          ""
        ) : (
          <>
            <p>Rectify the errors in the form !</p>{" "}
            <p className="error">{serverError}</p>
          </>
        )}
      </form>

      <Modal isOpen={openCModal} backdrop="static" toggle={closeCModal}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          Would you like to upload selfie from device camera or from your files?
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-info" onClick={webCam}>
            Choose webcam
          </button>
          <button
            className="btn btn-info"
            onClick={() => {
              inputFile.current.click();
              setOpenCModal(false);
            }}
          >
            Choose local files
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openModal} backdrop="static" toggle={closeModal}>
        {/* <ModalHeader >Modal title</ModalHeader> */}
        {cameraAvailable ? null : (
          <p className="text-danger">Camera is not available or not working.</p>
        )}
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />

        <ModalFooter>
          {cameraAvailable ? (
            <button className="btn btn-info" onClick={capture}>
              Capture photo
            </button>
          ) : (
            <button
              className="btn btn-info"
              onClick={() => {
                inputFile.current.click();
                setOpenCModal(false);
                setOpenModal(false);
              }}
            >
              Choose local files{" "}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default IdVerification;
